import { EventSourcePolyfill } from 'event-source-polyfill';
import { ENGINE_BASE_URL_KEY } from 'common/constants';
import { AUTH_SERVICE } from 'services';

export const getStreamingResult = async () => {
    const engineBaseUrl = getEngineBaseUrl();
    if (!engineBaseUrl) return;

    const streamingResultUrl = `${engineBaseUrl}/stream_result`;
    const token = await AUTH_SERVICE.getToken();
    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'text/event-stream'
    };

    return new EventSourcePolyfill(streamingResultUrl, { headers });
};

export const getStreamingVideoUrl = async () => {
    const engineBaseUrl = getEngineBaseUrl();
    if (!engineBaseUrl) return;

    const token = await AUTH_SERVICE.getToken();
    return `${engineBaseUrl}/video_feed?token=${token}`;
};

export const getEngineBaseUrl = () => {
    return localStorage.getItem(ENGINE_BASE_URL_KEY);
};

export const existEngineBaseUrl = () => {
    return localStorage.getItem(ENGINE_BASE_URL_KEY) !== null;
};

export const saveEngineBaseUrl = (url) => {
    localStorage.setItem(ENGINE_BASE_URL_KEY, url);
};

export const removeEngineBaseUrl = () => {
    localStorage.removeItem(ENGINE_BASE_URL_KEY);
};
