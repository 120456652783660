import { createSlice } from '@reduxjs/toolkit';
import { getServiceBoats, getStressEngineStatus } from 'redux/thunks';

const initialState = {
    serviceBoats: { data: [], loading: false }
};

export const stressDetectionSlice = createSlice({
    name: 'stressDetection',
    initialState,
    reducers: {
        resetServiceBoats: (state) => {
            state.serviceBoats = initialState.serviceBoats;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getServiceBoats.pending, (state) => {
            state.serviceBoats.loading = true;
        });
        builder.addCase(getServiceBoats.fulfilled, (state, action) => {
            state.serviceBoats.loading = false;
            state.serviceBoats.data = action.payload || initialState.serviceBoats.data;
        });
        builder.addCase(getServiceBoats.rejected, (state) => {
            state.serviceBoats.loading = false;
            state.serviceBoats.data = initialState.serviceBoats.data;
        });

        builder.addCase(getStressEngineStatus.pending, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: true };
            state.serviceBoats.data = newServiceBoatArray;
        });
        builder.addCase(getStressEngineStatus.fulfilled, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = {
                ...state.serviceBoats.data[index],
                ...action.payload,
                loading: false
            };
            state.serviceBoats.data = newServiceBoatArray;
        });
        builder.addCase(getStressEngineStatus.rejected, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: false };
            state.serviceBoats.data = newServiceBoatArray;
        });
    }
});

export const { resetServiceBoats } = stressDetectionSlice.actions;
