import i18n from 'translation/i18n';
import { GREEN, RED, YELLOW } from './color';

export const STRESS_DETECTION_ENGINE_BASE_URL_KEY = 'STRESS_DETECTION_ENGINE_BASE_URL';

export const STRESS_ZONES = [
    {
        name: 'stressZone.extremelyUnacceptable',
        min: 81,
        max: 100
    },
    {
        name: 'stressZone.unacceptable',
        min: 61,
        max: 80
    },
    {
        name: 'stressZone.unwanted',
        min: 41,
        max: 60
    },
    {
        name: 'stressZone.acceptable',
        min: 21,
        max: 40
    },
    {
        name: 'stressZone.wanted',
        min: 0,
        max: 20
    }
];

export const OXYGEN_ZONES = [
    {
        name: 'oxygenZone.acceptable',
        min: 110,
        max: 130,
        color: YELLOW
    },
    {
        name: 'oxygenZone.wanted',
        min: 80,
        max: 110,
        color: GREEN
    },
    {
        name: 'oxygenZone.acceptable',
        min: 60,
        max: 80,
        color: YELLOW
    },
    {
        name: 'oxygenZone.unacceptable',
        min: 0,
        max: 60,
        color: RED
    }
];

export const STRESS_LEVEL_STATUSES = [
    { name: i18n.t('stressLevelStatus.all'), value: null },
    { name: i18n.t('stressLevelStatus.high'), value: 'High' },
    { name: i18n.t('stressLevelStatus.low'), value: 'Low' }
];

export const CORRELATION_CATEGORY = {
    SUM_EXTREMELY_UNACCEPTABLE_STRESS_ZONE_TIME_PER_THROW:
        'sumExtremelyUnacceptableStressZoneTimePerThrow',
    SUM_HIGH_STRESS_ZONE_TIME_PER_EVENT: 'sumHighStressZoneTimePerEvent',
    AVERAGE_OXYGEN_LEVEL_PER_THROW: 'averageOxygenLevelPerThrow',
    SUM_CROWDING_TIME_PER_THROW: 'sumCrowdingTimePerThrow',
    SUM_MORTALITY_PER_EVENT: 'sumMortalityPerEvent',
    DAYS_SINCE_LAST_PER_EVENT: 'daysSinceLastPerEvent'
};
