import { createAsyncThunk } from '@reduxjs/toolkit';
import { STRESS_DETECTION_API, STRESS_ENGINE_STATUS_API } from 'common/constants';
import { API, STRESS_ENGINE_API } from 'services';

export const getServiceBoats = createAsyncThunk(
    'stressDetections/getServiceBoats',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${STRESS_DETECTION_API}/service-boats`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressEngineStatus = createAsyncThunk(
    'stressDetections/getStressEngineStatus',
    async ({ baseUrl, filters }, { rejectWithValue }) => {
        try {
            const { data } = await STRESS_ENGINE_API.get(
                baseUrl + STRESS_ENGINE_STATUS_API,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
