import { Card, Col, Row, Spin } from 'antd';
import { DATE_TIME_FORMAT } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getScoringEventDetail } from 'redux/thunks';

const ScoringInfo = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: scoringEvent, loading } = useSelector((s) => s.scoringEvent.detail);
    const initialFilters = useSelector((s) => s.filter.data);

    useEffect(() => {
        if (initialFilters.selectedScoringEventId) {
            dispatch(getScoringEventDetail(initialFilters.selectedScoringEventId));
        }
    }, [initialFilters.selectedScoringEventId]);

    const infoList = [
        {
            key: 'scoringEvent.location',
            value: `${scoringEvent.siteName ?? ''} - ${scoringEvent.penNumber ?? ''}`,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.boatName',
            value: scoringEvent.boatName,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.method',
            value: scoringEvent.method,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.time',
            value: (
                <>
                    <span>{formatDateTime(scoringEvent.startTime, DATE_TIME_FORMAT)}</span>
                    {' - '}
                    <span className="inline-block">
                        {formatDateTime(scoringEvent.stopTime, DATE_TIME_FORMAT)}
                    </span>
                </>
            ),
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.seaTemperature',
            value: scoringEvent.seaTemperature,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.orcastOrStringOfPearls',
            value:
                scoringEvent.orcastOrStringOfPearls === 0
                    ? t('scoringEvent.orcast')
                    : t('scoringEvent.stringOfPearls'),
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.beforeOrAfter',
            value: scoringEvent.isBeforeDelicingEvent
                ? t('scoringEvent.before')
                : t('scoringEvent.after'),
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.numberOfFish',
            value: scoringEvent.scoringPerFishes?.length,
            col: { xs: 12, xl: 6 }
        }
    ];

    return (
        <Card className="event-info mt-2">
            <Spin spinning={loading}>
                <Row className="text-base" gutter={8}>
                    {infoList.map((info) => (
                        <Col key={info.key} {...info.col} className="flex flex-col mb-2">
                            <span className="font-semibold">{t(info.key)}</span>
                            <span>{info.value}</span>
                        </Col>
                    ))}
                </Row>
            </Spin>
        </Card>
    );
};

export default ScoringInfo;
