import { appInsights } from 'AppInsights';
import { Button, Col, Row } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_SELECTED,
    BUTTON_GRAY_UN_SELECTED,
    STRESS_LEVEL_STATUSES
} from 'common/constants';
import { FDMultipleSelection, FDRangeDate } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getPensSelection,
    getServiceBoatsSelection,
    getSitesSelection,
    getStressAnalysisForCrowdingTimePerThrow,
    getStressAnalysisForEvent,
    getStressAnalysisForFish,
    getStressAnalysisForMortalityPerEvent,
    getStressAnalysisForOxygenLevelPerThrow,
    getStressAnalysisForStressLevelPerThrow,
    getStressAnalysisForStressZonePerThrow,
    updateFilters
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const allFishwellFishIds = useSelector((s) => s.fishwellFishIdSelection.data);
    const serviceBoatsSelection = useSelector((s) => s.serviceBoatSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [fishGroupsSelection, setFishGroupsSelection] = useState([]);
    const [filters, setFilters] = useState({
        siteIds: initialFilters.siteIds,
        penIds: initialFilters.penIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate,
        fishGroupIds: initialFilters.fishGroupIds,
        serviceBoatIds: initialFilters.serviceBoatIds,
        stressLevelStatus: initialFilters.stressLevelStatus
    });

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
        dispatch(getFishwellFishIdsSelection());
        dispatch(getServiceBoatsSelection());
    }, []);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.siteIds.length)
            pensSelection = allPens.filter((pen) => initialFilters.siteIds.includes(pen.siteId));

        setPensSelection(pensSelection);
    }, [allPens]);

    useEffect(() => {
        setFishGroupsSelection(allFishwellFishIds);
    }, [allFishwellFishIds]);

    useEffect(() => {
        const filters = {
            siteIds: initialFilters.siteIds,
            penIds: initialFilters.penIds,
            fishGroupIds: initialFilters.fishGroupIds,
            serviceBoatIds: initialFilters.serviceBoatIds,
            stressLevelStatus: initialFilters.stressLevelStatus,
            fromDate: initialFilters.fromDate,
            toDate: initialFilters.toDate
        };

        dispatch(getStressAnalysisForEvent(filters));
        dispatch(getStressAnalysisForFish(filters));
        dispatch(getStressAnalysisForStressLevelPerThrow(filters));
        dispatch(getStressAnalysisForCrowdingTimePerThrow(filters));
        dispatch(getStressAnalysisForStressZonePerThrow(filters));
        dispatch(getStressAnalysisForOxygenLevelPerThrow(filters));
        dispatch(getStressAnalysisForMortalityPerEvent(filters));
    }, [
        initialFilters.siteIds,
        initialFilters.penIds,
        initialFilters.fishGroupIds,
        initialFilters.serviceBoatIds,
        initialFilters.stressLevelStatus,
        initialFilters.fromDate,
        initialFilters.toDate
    ]);

    const handleSitesChange = (siteIds) => {
        let selectedPens = filters.penIds;
        let newPensSelections = allPens;

        let selectedFishGroups = filters.fishGroupIds;
        let newFishGroupsSelections = allFishwellFishIds;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) => siteIds.includes(pen.siteId));

            selectedPens = filters.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );

            newFishGroupsSelections = allFishwellFishIds.filter((fishGroup) =>
                siteIds.includes(fishGroup.siteId)
            );

            selectedFishGroups = filters.fishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
        }

        setPensSelection(newPensSelections);
        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            siteIds: siteIds,
            penIds: selectedPens,
            fishGroupIds: selectedFishGroups
        });
    };

    const handlePensChange = (penIds) => {
        let selectedFishGroups = filters.fishGroupIds;
        let newFishGroupsSelections = allFishwellFishIds;

        if (penIds.length) {
            newFishGroupsSelections = allFishwellFishIds.filter((fishGroup) =>
                penIds.includes(fishGroup.penId)
            );

            selectedFishGroups = filters.fishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
        }

        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            penIds: penIds,
            fishGroupIds: selectedFishGroups
        });
    };

    const handleFishGroupsChange = (fishGroupIds) => {
        setFilters({
            ...filters,
            fishGroupIds: fishGroupIds
        });
    };

    const handleServiceBoatsChange = (serviceBoatIds) => {
        setFilters({
            ...filters,
            serviceBoatIds: serviceBoatIds
        });
    };

    const handleStressLevelStatusChange = (stressLevelStatus) => {
        setFilters({
            ...filters,
            stressLevelStatus: stressLevelStatus.value
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterSubmit = () => {
        dispatch(
            updateFilters({
                siteIds: filters.siteIds,
                penIds: filters.penIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate,
                fishGroupIds: filters.fishGroupIds,
                serviceBoatIds: filters.serviceBoatIds,
                stressLevelStatus: filters.stressLevelStatus
            })
        );

        appInsights.trackEvent({ name: 'Stress analysis filter apply' });
    };

    return (
        <div className="filter-bar">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>
            <Row className="md:text-base filter">
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.site')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.sitesPlaceholder')}
                        listSelectItem={sitesSelection}
                        onChange={handleSitesChange}
                        value={filters.siteIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.pen')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.pensPlaceholder')}
                        listSelectItem={pensSelection}
                        onChange={handlePensChange}
                        value={filters.penIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.fishGroups')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.fishGroupsPlaceholder')}
                        listSelectItem={fishGroupsSelection.map((fishGroup) => ({
                            ...fishGroup,
                            text: `${fishGroup.text} - ${fishGroup.penNumber} - ${fishGroup.siteName}`
                        }))}
                        onChange={handleFishGroupsChange}
                        value={filters.fishGroupIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.boat')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.boatsPlaceholder')}
                        listSelectItem={serviceBoatsSelection}
                        onChange={handleServiceBoatsChange}
                        value={filters.serviceBoatIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                    <FDRangeDate
                        placeholder={[
                            t('general.filterBar.startDatePlaceholder'),
                            t('general.filterBar.endDatePlaceholder')
                        ]}
                        onChange={handleRangeDateChange}
                        value={[filters.fromDate, filters.toDate]}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.stressLevelStatus')}</div>
                    <div className="flex gap-x-3">
                        {STRESS_LEVEL_STATUSES.map((stressLevelStatus) => (
                            <Button
                                key={stressLevelStatus.value}
                                className={
                                    stressLevelStatus.value === filters.stressLevelStatus
                                        ? BUTTON_GRAY_SELECTED
                                        : BUTTON_GRAY_UN_SELECTED
                                }
                                onClick={() => handleStressLevelStatusChange(stressLevelStatus)}
                            >
                                {stressLevelStatus.name}
                            </Button>
                        ))}
                    </div>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={3} className="flex items-end">
                    <Button className={BUTTON_BLUE_CLASS} onClick={handleFilterSubmit}>
                        {t('general.filterBar.apply')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterBar;
