import axios from 'axios';
import { BASE_API_URL, ENGINE_BASE_URL_KEY } from 'common/constants';
import qs from 'qs';
import { getToken } from '../Auth';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        accept: 'application/json',
        contentType: 'application/json',
        Authorization: ''
    }
};

const _get = (url, params = {}, options = {}) => {
    const engineBaseUrl = localStorage.getItem(ENGINE_BASE_URL_KEY);
    if (!engineBaseUrl) return;

    return instance.get(`${engineBaseUrl}/${url}`, {
        ...defaultOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const post = (url, body = {}, options = {}) =>
    instance.post(BASE_API_URL + url, body, { ...defaultOptions, ...options });
const put = (url, body = {}, options = {}) =>
    instance.put(BASE_API_URL + url, body, { ...defaultOptions, ...options });
const patch = (url, body = {}, options = {}) =>
    instance.patch(BASE_API_URL + url, body, { ...defaultOptions, ...options });
const _delete = (url, options = {}) =>
    instance.delete(BASE_API_URL + url, { ...defaultOptions, ...options });

const interceptorHandleRequest = async (config) => {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    return Promise.reject(error.response.data.errors || error.response.data.message);
};

instance.interceptors.request.use(interceptorHandleRequest, handleError);
instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { _delete as delete, _get as get, patch, post, put };
