import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import useAppAbility from 'casl/can';
import { Forbidden } from 'pages';

export default function ProtectedRoute({ children, isPrivate = false, role }) {
    const { can } = useAppAbility();
    const { inProgress } = useMsal();

    if (inProgress !== InteractionStatus.None) return;

    if (isPrivate && !can('manage', role, ['all'])) {
        return <Forbidden />;
    }

    return <>{children}</>;
}
