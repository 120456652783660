import { Switch } from 'antd';
import './Switching.scss';

const FDSwitching = ({
    name,
    checked = false,
    dataTestId,
    onChange = () => {},
    handleSwitchChange = () => {}
}) => {
    const onClick = () => {
        handleSwitchChange(name);
    };

    const handleOnchange = (checked) => {
        onChange(checked);
    };

    return (
        <Switch
            checkedChildren={name}
            unCheckedChildren={name}
            className="mt-2 mr-6 font-medium switch"
            onClick={onClick}
            checked={checked}
            onChange={handleOnchange}
            data-testid={dataTestId}
        />
    );
};

export default FDSwitching;
