import { withPageViewTracking } from 'hoc';
import FilterBar from './FilterBar';
import FishMortality from './FishMortality';
import ReportTable from './ReportTable';
import SalmonWound from './SalmonWound';
import './MortalityAnalysis.scss';

const MortalityAnalysis = () => {
    return (
        <div className="mortality-analysis">
            <FilterBar />

            <FishMortality />

            <SalmonWound />

            <ReportTable />
        </div>
    );
};

export default withPageViewTracking(MortalityAnalysis, 'Mortality analysis');
