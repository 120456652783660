import { Layout as LibLayout } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ENGINE_COUNTING_URL } from 'common/constants';

const Sidebar = () => {
    const { Sider } = LibLayout;
    const [collapsed] = useState(false);

    return (
        <Sider trigger={null} className="sidebar" collapsible collapsed={collapsed} width="250">
            <Link to={ENGINE_COUNTING_URL}>
                <div className={`brand-name ${collapsed && 'collapsed'}`}>
                    <div className="logo" />
                </div>
            </Link>
        </Sider>
    );
};

export default Sidebar;
