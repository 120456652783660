import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Spin } from 'antd';
import { Button } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_LIGHT_GREEN_CLASS,
    BUTTON_RED_CLASS,
    GRAY,
    LIGHT_GREEN,
    ORANGE
} from 'common/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/store';
import { getStressEngineStatus } from 'redux/thunks';

const ServiceBoatStatus = ({ serviceBoat = {}, onPenListOpen = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (serviceBoat.hasEngineBaseUrl) {
            dispatch(
                getStressEngineStatus({
                    baseUrl: serviceBoat.engineBaseUrl,
                    serviceBoatId: serviceBoat.id
                })
            );
        }
    }, [serviceBoat.engineBaseUrl]);

    const handlerStartClick = () => {
        onPenListOpen(serviceBoat);
    };

    const handleStopClick = () => {};

    const handleViewClick = () => {};

    const getServiceBoatStyle = () => {
        if (serviceBoat.isDetecting) {
            return {
                borderColor: 'border-blue-500',
                icon: <Spin />,
                status: `${t('stressDetection.isDetecting')} ${serviceBoat?.penNumber || ''}...`,
                button: (
                    <>
                        <Button className={BUTTON_BLUE_CLASS} onClick={() => handleViewClick}>
                            {t('button.view')}
                        </Button>
                        <Button className={BUTTON_RED_CLASS} onClick={() => handleStopClick()}>
                            {t('button.stop')}
                        </Button>
                    </>
                )
            };
        }

        if (serviceBoat.hasEngineBaseUrl) {
            return {
                borderColor: 'border-green-600',
                icon: <CheckCircleFilled style={{ fontSize: '18px', color: LIGHT_GREEN }} />,
                status: t('stressDetection.readyToStart'),
                button: (
                    <Button
                        className={BUTTON_LIGHT_GREEN_CLASS}
                        onClick={() => handlerStartClick()}
                    >
                        {t('button.start')}
                    </Button>
                )
            };
        }

        if (serviceBoat.hasEngineConfig) {
            return {
                borderColor: 'border-orange-500',
                icon: <InfoCircleFilled style={{ fontSize: '18px', color: ORANGE }} />,
                status: t('stressDetection.noEngineBaseUrl')
            };
        }

        return {
            borderColor: 'border-gray-500',
            icon: <CloseCircleFilled style={{ fontSize: '18px', color: GRAY }} />,
            status: t('stressDetection.noEngineConfig')
        };
    };

    return (
        <Spin spinning={serviceBoat.loading || false}>
            <div
                className={`border border-solid rounded p-2 bg-white w-48 h-36 ${
                    getServiceBoatStyle().borderColor
                }`}
            >
                <div className="flex items-center">
                    {getServiceBoatStyle().icon}
                    <span className="font-bold ml-2 truncate">{serviceBoat.name}</span>
                </div>
                <p className="font-medium mt-2">{getServiceBoatStyle().status}</p>

                <div className="flex justify-around">{getServiceBoatStyle().button}</div>
            </div>
        </Spin>
    );
};

export default ServiceBoatStatus;
