import FDNotificationBell from 'components/common/Notification';

const AboveAverageBell = ({ hasNotification = false }) => {
    return (
        hasNotification && (
            <div
                className="bg-white h-8 w-8 rounded flex justify-center items-center absolute top-1 right-1"
                data-testid="notification-bell"
            >
                <FDNotificationBell dot={true} />
            </div>
        )
    );
};

export default AboveAverageBell;
