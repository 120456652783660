import React from 'react';
import FilterEvents from './FilterEvents';
import ScoringInfo from './ScoringInfo/ScoringInfo';
import { useSelector } from 'react-redux';
import ScoringPerFish from './ScoringPerFish';

const ScoringEvent = () => {
    const { selectedScoringEventId } = useSelector((s) => s.filter.data);

    return (
        <>
            <FilterEvents />
            {selectedScoringEventId && (
                <>
                    <ScoringInfo />
                    <ScoringPerFish />
                </>
            )}
        </>
    );
};

export default ScoringEvent;
