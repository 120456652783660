import { createAsyncThunk } from '@reduxjs/toolkit';
import { SCORING_EVENT_API } from 'common/constants';
import moment from 'moment';
import { API } from 'services';

export const getScoringEventDetail = createAsyncThunk(
    'events/getScoringEventDetail',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${SCORING_EVENT_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getScoringEventList = createAsyncThunk(
    'events/getScoringEventList',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(`${SCORING_EVENT_API}/list`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
