import { Card, Col, Row, Spin } from 'antd';
import { PEN_NOTIFICATION_EXPLANATION } from 'common/constants';
import { getMortalityStatus, formatNumberToFixedDigits } from 'common/utils';
import { FDGauge, FDTooltip } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getPensNotifications } from 'redux/thunks';

const PenNotification = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: pensNotification, loading } = useSelector((s) => s.pensNotification);

    useEffect(() => {
        dispatch(
            getPensNotifications({
                date: initialFilters.daily.fromDate,
                penIds: [initialFilters.daily.penId]
            })
        );
    }, [initialFilters.daily]);

    return (
        <Spin spinning={loading}>
            {!pensNotification[0]?.penNumber ? (
                <Card className="text-center">
                    <img alt="ava" src={require('assets/Empty_Box.png')} />
                    <p className="text-neutral-300">No Data</p>
                </Card>
            ) : (
                <Card bodyStyle={{ textAlign: 'center' }} bordered={false}>
                    <div className="flex justify-end">
                        <FDTooltip explainInfo={PEN_NOTIFICATION_EXPLANATION} />
                    </div>

                    <Row gutter={24} className="divide-slate-700">
                        <Col
                            xl={8}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="flex flex-col justify-center items-center mb-5"
                        >
                            <span className="font-semibold text-xl">
                                Pen {pensNotification[0]?.penNumber}
                            </span>
                            <FDGauge
                                width={280}
                                height={180}
                                percent={pensNotification[0]?.deviation}
                            />
                        </Col>

                        <Col
                            xl={8}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="flex justify-center items-center"
                        >
                            <div className="flex justify-center">
                                <table>
                                    <tbody>
                                        <tr className="bg-slate">
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base py-1 mr-2">
                                                    {t('dailyReport.mortalityStatistics.mortality')}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl leading-7 text-left">
                                                {pensNotification[0]?.totalCount}
                                            </td>
                                        </tr>
                                        <tr className="bg-slate">
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base py-1 mr-2">
                                                    {t(
                                                        getMortalityStatus(
                                                            pensNotification[0]?.deviation
                                                        )
                                                    )}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl leading-7 text-left">
                                                <span>
                                                    {Math.round(
                                                        pensNotification[0]?.deviation * 100
                                                    ) + '%'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr className="bg-slate">
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base py-1 mr-2">
                                                    {t(
                                                        'dailyReport.mortalityStatistics.penAverage'
                                                    ) + ':'}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl leading-7 text-left">
                                                {pensNotification[0]?.penAverage &&
                                                    formatNumberToFixedDigits(
                                                        pensNotification[0]?.penAverage
                                                    )}
                                            </td>
                                        </tr>
                                        <tr className="bg-slate">
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base py-1 mr-2">
                                                    {t(
                                                        'dailyReport.mortalityStatistics.siteAverage'
                                                    ) + ':'}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl leading-7 text-left">
                                                {pensNotification[0]?.siteAverage &&
                                                    formatNumberToFixedDigits(
                                                        pensNotification[0]?.siteAverage
                                                    )}
                                            </td>
                                        </tr>
                                        <tr className="bg-slate">
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base py-1 mr-2">
                                                    {t(
                                                        'dailyReport.mortalityStatistics.companyAverage'
                                                    ) + ':'}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl leading-7 text-left">
                                                {pensNotification[0]?.companyAverage &&
                                                    formatNumberToFixedDigits(
                                                        pensNotification[0]?.companyAverage
                                                    )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col
                            xl={8}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="flex justify-center items-center"
                        >
                            <table>
                                <tbody>
                                    <tr className="bg-slate">
                                        <td className="text-right">
                                            <span className="text-gray-500 text-base py-1 mr-2">
                                                {t('dailyReport.mortalityStatistics.counted')}
                                            </span>
                                        </td>
                                        <td className="font-semibold text-xl leading-7 text-left">
                                            {!pensNotification[0]?.isActive
                                                ? 'Not Active'
                                                : pensNotification[0]?.totalCount > 0
                                                ? 'Ok'
                                                : 'No'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>
            )}
        </Spin>
    );
};

export default PenNotification;
