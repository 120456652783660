import { Menu, Popover } from 'antd';
import { appInsights } from 'AppInsights';
import noFlag from 'assets/images/flags/no.svg';
import usaFlag from 'assets/images/flags/usa.svg';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { updateLocale } from 'redux/thunks';
import './CountryPicker.scss';

const CountryPicker = () => {
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();

    const flags = {
        nb: noFlag,
        en: usaFlag
    };

    const locale = useSelector((s) => s.locale.data);

    useEffect(() => {
        i18n?.changeLanguage(locale);
        moment.locale(locale);
    }, [locale]);

    const handleChangeLanguage = (language) => {
        dispatch(updateLocale(language));

        appInsights.trackEvent({ name: 'Language change', properties: { language } });
    };

    return (
        <Popover
            getPopupContainer={(trigger) => trigger.parentElement}
            content={
                <Menu mode="inline" className="auth-menu" selectable={false}>
                    <Menu.Item
                        key="nb"
                        onClick={() => handleChangeLanguage('nb')}
                        className="hover:bg-gray-300 flag-picker-item"
                    >
                        <img
                            className="country-picker-flag w-8 h-6 cursor-pointer object-cover mr-1"
                            src={noFlag}
                            alt="Flag"
                        />
                        Norwegian
                    </Menu.Item>
                    <Menu.Item
                        key="en"
                        onClick={() => handleChangeLanguage('en')}
                        className="hover:bg-gray-300 flag-picker-item"
                    >
                        <img
                            className="country-picker-flag w-8 h-6 cursor-pointer object-cover mr-1"
                            src={usaFlag}
                            alt="Flag"
                        />
                        English
                    </Menu.Item>
                </Menu>
            }
            placement="bottomRight"
        >
            <div>
                <img
                    className="country-picker-flag w-8 h-6 cursor-pointer object-cover"
                    src={flags[locale]}
                    alt="Flag"
                />
            </div>
        </Popover>
    );
};

export default CountryPicker;
