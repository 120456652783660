import { Gauge } from '@ant-design/plots';
import {
    BLUE,
    DEFAULT_MAX_PERCENT,
    DEFAULT_MIN_PERCENT,
    GREEN,
    MAX_AVERAGE_PERCENT,
    MIN_AVERAGE_PERCENT,
    RED
} from 'common/constants';
import { useEffect, useState } from 'react';

const Normalizer = (min, max) => ({
    normalize: (x) => min + x * (max - min),
    denormalize: (x) => (x - min) / (max - min)
});

const FDGauge = ({ height, width, percent = 0, isSmall = false }) => {
    const [config, setConfig] = useState({});

    useEffect(() => {
        const value = percent * 100;
        let minPercent = DEFAULT_MIN_PERCENT,
            maxPercent = DEFAULT_MAX_PERCENT;

        if (value < minPercent) {
            minPercent = value;
        } else if (value > maxPercent) {
            maxPercent = value;
        }

        const gaugeNormalizer = Normalizer(minPercent, maxPercent);

        const config = {
            innerRadius: 0.8,
            percent: gaugeNormalizer.denormalize(value),
            range: {
                ticks: [
                    gaugeNormalizer.denormalize(minPercent),
                    gaugeNormalizer.denormalize(MIN_AVERAGE_PERCENT),
                    gaugeNormalizer.denormalize(MAX_AVERAGE_PERCENT),
                    gaugeNormalizer.denormalize(maxPercent)
                ],
                color: [GREEN, BLUE, RED]
            },
            meta: {
                percent: {
                    ticks: [
                        gaugeNormalizer.denormalize(minPercent),
                        gaugeNormalizer.denormalize(MIN_AVERAGE_PERCENT),
                        gaugeNormalizer.denormalize(MAX_AVERAGE_PERCENT),
                        gaugeNormalizer.denormalize(maxPercent),
                        gaugeNormalizer.denormalize(value)
                    ],
                    formatter: (text) => {
                        const num = Math.round(gaugeNormalizer.normalize(+text));
                        return isSmall ? '' : `${num >= 0 ? `${num}` : num}%`;
                    }
                }
            },
            indicator: {
                pointer: {
                    style: {
                        stroke: '#6d6d6d'
                    }
                },
                pin: {
                    style: {
                        stroke: '#6d6d6d'
                    }
                }
            },
            statistic: {
                content: {
                    style: {
                        fontSize: isSmall ? '14px' : '18px',
                        lineHeight: '18px',
                        marginTop: '5px'
                    },
                    formatter: ({ percent }) => {
                        const num = Math.round(gaugeNormalizer.normalize(percent));
                        return `${num > 0 ? `${num}` : num}%`;
                    }
                }
            },
            axis: {
                subTickLine: {
                    count: 0
                }
            }
        };

        setConfig(config);
    }, [percent]);

    return <Gauge height={height} width={width} {...config} />;
};

export default FDGauge;
