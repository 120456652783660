import { AreaChartOutlined, HomeOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Avatar, Button, Layout as LibLayout, Menu, Popover } from 'antd';
import defaultAvatar from 'assets/Default_Avatar.png';
import CountryPicker from 'layouts/Header/CountryPicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const { t } = useTranslation();
    const { Header } = LibLayout;
    const { instance } = useMsal();

    const { data: profile } = useSelector((s) => s.profile);

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <Header theme="dark" className="header flex items-center justify-between z-10 px-4">
            <Link to="/">
                <Button
                    type="button"
                    icon={<HomeOutlined className="text-xl" />}
                    className="bg-whit focus-within:bg-white text-sky-950 hover:bg-gray-100 hover:text-sky-950 focus-within:text-sky-950 text-sm font-semibold 
                        h-8 rounded border border-sky-700 hover:border-sky-800 flex justify-center items-center"
                >
                    {t('countingPage.goToHomepage')}
                </Button>
            </Link>

            <div className="flex gap-x-5">
                <CountryPicker />

                <Popover
                    className="profile-dropdown"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    content={
                        <Menu mode="inline" className="auth-menu" selectable={false}>
                            <Menu.Item key="1">
                                <NavLink to="/profile">
                                    <UserOutlined /> {t('layout.header.viewProfile')}
                                </NavLink>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="2">
                                <NavLink to="/">
                                    <AreaChartOutlined /> {t('layout.header.analysis')}
                                </NavLink>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="3" onClick={handleLogout}>
                                <LogoutOutlined /> {t('layout.header.logout')}
                            </Menu.Item>
                        </Menu>
                    }
                    placement="bottomRight"
                >
                    <span className="user-name cursor-pointer hidden xl:!inline">
                        {t('layout.header.greeting')} {profile.name || '...'}
                    </span>
                    <Avatar className="cursor-pointer w-8" src={profile.avatar || defaultAvatar} />
                </Popover>
            </div>
        </Header>
    );
};

export default Header;
