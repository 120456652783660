import { createSlice } from '@reduxjs/toolkit';
import {
    DEFAULT_CHART_TYPE,
    DEFAULT_GRAPH,
    DEFAULT_REPORT,
    PREVIOUS_DAYS_DEFAULT,
    TIME_PERIOD_DEFAULT
} from 'common/constants';
import { getPreviousDate } from 'common/utils';
import moment from 'moment';
import { updateFilters } from 'redux/thunks';

const initialState = {
    data: {
        daily: {
            fromDate: moment().startOf('day'),
            toDate: moment().endOf('day'),
            siteId: null,
            penId: null
        },
        fromDate: getPreviousDate(PREVIOUS_DAYS_DEFAULT),
        toDate: moment().endOf('day'),
        timePeriod: {
            value: TIME_PERIOD_DEFAULT.id,
            fromDate: getPreviousDate(PREVIOUS_DAYS_DEFAULT)
        },
        comparison: {
            siteIds: [],
            penIds: []
        },
        graphType: DEFAULT_GRAPH,
        chartType: DEFAULT_CHART_TYPE,
        reportType: DEFAULT_REPORT,
        fromDead: null,
        toDead: null,
        fishNames: [],
        siteIds: [],
        penIds: [],
        fishGroupIds: [],
        serviceBoatIds: [],
        selectedStressEventId: null,
        selectedScoringEventId: null,
        selectedMortalityId: null,
        selectedProcessId: null,
        selectedFishGroupId: null,
        stressLevelStatus: null
    },
    loading: false
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {},
    extraReducers: {
        [updateFilters.pending]: (state) => {
            state.loading = true;
        },
        [updateFilters.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload ? { ...state.data, ...action.payload } : initialState.data;
        },
        [updateFilters.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
