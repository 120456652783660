import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    DEAD_FISH_COUNTING_MORTALITY_API,
    DEAD_FISH_MORTALITY_API,
    DEAD_FISH_MORTALITY_CARD_API,
    FISH_GROUP_MORTALITY_API,
    REPORT_TABLE_API,
    SALMON_WOUND_API,
    STOCK_COUNT_API
} from 'common/constants';
import moment from 'moment';
import { API } from 'services';

export const getDeadFishCountingMortality = createAsyncThunk(
    'reports/getDeadFishCountingMortality',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(DEAD_FISH_COUNTING_MORTALITY_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getDeadFishMortalityCard = createAsyncThunk(
    'reports/getDeadFishMortalityCard',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(DEAD_FISH_MORTALITY_CARD_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getReportSalmonWound = createAsyncThunk(
    'reports/getReportSalmonWound',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(SALMON_WOUND_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getDeadFishMortality = createAsyncThunk(
    'reports/getDeadFishMortality',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(DEAD_FISH_MORTALITY_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishGroupMortality = createAsyncThunk(
    'reports/getFishGroupMortality',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(FISH_GROUP_MORTALITY_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getReportTable = createAsyncThunk(
    'reports/getReportTable',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(REPORT_TABLE_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStockCount = createAsyncThunk(
    'reports/getStockCount',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STOCK_COUNT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
