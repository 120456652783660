import { DribbbleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tree } from 'antd';
import 'antd/dist/antd.min.css';
import PropTypes from 'prop-types';
import './FDTreeTransfer.scss';

export const renderTreeNodes = (treeData, selectedKeys) => {
    return treeData
        .map((data) => ({
            ...data,
            children: data.children.filter((child) => selectedKeys.includes(child.key))
        }))
        .filter((data) => data.children.length)
        .map((item) => (
            <Tree.TreeNode title={item.title} key={item.key} dataRef={item} checked>
                {item.children.map((subItem) => (
                    <Tree.TreeNode
                        title={subItem.title}
                        key={subItem.key}
                        dataRef={subItem}
                        checked
                        switcherIcon={<DribbbleOutlined />}
                    />
                ))}
            </Tree.TreeNode>
        ));
};

export const FDTreeTransfer = ({
    treeData = [],
    selectedKeys = [],
    messageForEmptyTreeData = '',
    onSelectedKeysChange = () => {}
}) => {
    const onCheck = (selectedKeys) => {
        onSelectedKeysChange(
            selectedKeys.filter((key) => !treeData.find((node) => node.key === key))
        );
    };

    return (
        <div className="tree-transfer">
            <Row type="flex" gutter={20}>
                <Col span={12}>
                    <Card className="h-full">
                        {treeData.length > 0 ? (
                            <Tree
                                checkable={true}
                                defaultExpandAll={false}
                                onCheck={onCheck}
                                checkedKeys={selectedKeys}
                                treeData={treeData}
                            />
                        ) : (
                            <p className="text-center">{messageForEmptyTreeData}</p>
                        )}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Tree defaultExpandAll={true}>
                            {renderTreeNodes(treeData, selectedKeys)}
                        </Tree>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

FDTreeTransfer.propTypes = {
    treeData: PropTypes.array,
    selectedKeys: PropTypes.array,
    messageForEmptyTreeData: PropTypes.string,
    onSelectedKeysChange: PropTypes.func
};

export default FDTreeTransfer;
