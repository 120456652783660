import { Spin } from 'antd';
import fishwellLogo from 'assets/Fishwell_Logo.png';
import { alertErrorMessage } from 'common/utils';
import useTimer from 'hooks/useTimer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getEngineStatus } from 'redux/thunks';
import { ENGINE_SERVICE, NOTIFICATION_SERVICE } from 'services';
import './Content.scss';
import FormAndResult from './FormAndResult';
import StreamingCounting from './StreamingCounting';

const Content = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { timer, updateTimer, handleStartTimer, handleResetTimer } = useTimer();

    const [selectedPen, setSelectedPen] = useState();
    const [preSelectedPen, setPreSelectedPen] = useState();
    const [selectedSite, setSelectedSite] = useState();

    const { data: sitesSelection } = useSelector((s) => s.engineSite);
    const { data: engineStatus, loading: statusLoading } = useSelector((s) => s.engineStatus);

    useEffect(() => {
        // get the engine status to check if the engine is counting
        const fetchEngineStatus = async () => {
            try {
                var response = await dispatch(getEngineStatus()).unwrap();

                if (response?.isCounting) {
                    // the engine has started counting before
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('countingPage.notification.hasStartedCounting'),
                        duration: 15
                    });
                }
            } catch (error) {
                alertErrorMessage(error);
            }
        };

        if (ENGINE_SERVICE.existEngineBaseUrl()) {
            fetchEngineStatus();
        }
    }, []);

    useEffect(() => {
        if (engineStatus.isCounting) {
            updateTimer(engineStatus.countingTime || 0);

            setSelectedPen({ siteName: engineStatus.siteName, penNumber: engineStatus.penNumber });
            setPreSelectedPen({
                siteName: engineStatus.siteName,
                penNumber: engineStatus.penNumber
            });

            const site = sitesSelection.find(
                ({ text, localityNumber }) =>
                    engineStatus.siteName === text && engineStatus.localityNumber === localityNumber
            );
            setSelectedSite(site);
        } else {
            handleResetTimer();
            setSelectedPen(null);
            setPreSelectedPen(null);
            setSelectedSite(null);
        }
    }, [engineStatus, sitesSelection]);

    return (
        <div className="counting-page">
            <div className="flex justify-center mb-8">
                <img src={fishwellLogo} alt="" className="pl-10 xs:w-3/4 md:w-2/3 xl:w-1/3" />
            </div>

            {statusLoading ? (
                <div className="text-center">
                    <Spin size="large"></Spin>
                </div>
            ) : selectedPen ? (
                // if the selectedPen is not null, it means the engine is counting -> show the streaming counting page
                <StreamingCounting
                    selectedSite={selectedSite}
                    selectedPen={selectedPen}
                    setSelectedPen={setSelectedPen}
                    timer={timer}
                    onResetTimer={handleResetTimer}
                />
            ) : (
                // if the engine is not counting -> show the form to select site/pen and start counting
                <FormAndResult
                    selectedSite={selectedSite}
                    preSelectedPen={preSelectedPen}
                    setSelectedSite={setSelectedSite}
                    setSelectedPen={setSelectedPen}
                    setPreSelectedPen={setPreSelectedPen}
                    onStartTimer={handleStartTimer}
                    onUpdateTimer={updateTimer}
                />
            )}
        </div>
    );
};

export default Content;
