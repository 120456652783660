import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { memo } from 'react';
import CrowdingArea from './CrowdingArea';
import NumberOfFish from './NumberOfFish';
import OxygenLevel from './OxygenLevel';
import Speed from './Speed';
import StressLevel from './StressLevel';
import StressZone from './StressZone';
import Temperature from './Temperature';
import CurrentEfficiency from './CurrentEfficiency';
import TotalEfficiency from './TotalEfficiency';
import FishHealthCondition from '../FishHealthCondition';

const EventCharts = ({ sliderValues }) => {
    return (
        <Row gutter={8}>
            <Col xs={24} xl={12}>
                <StressLevel sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <StressZone sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <OxygenLevel sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <FishHealthCondition />
            </Col>

            <Col xs={24} xl={12}>
                <NumberOfFish sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <Temperature sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <Speed sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <CrowdingArea sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <CurrentEfficiency sliderValues={sliderValues} />
            </Col>

            <Col xs={24} xl={12}>
                <TotalEfficiency sliderValues={sliderValues} />
            </Col>
        </Row>
    );
};

EventCharts.propTypes = {
    sliderValues: PropTypes.array
};

export default memo(EventCharts);
