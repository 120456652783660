import { Card, Spin } from 'antd';
import defaultLogo from 'assets/Empty_Box.png';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const FDSliderSelection = ({
    selectedId,
    listSelectItem = [],
    loading = false,
    onSelected = () => {}
}) => {
    useEffect(() => {
        const index = listSelectItem.findIndex((obj) => obj.id == selectedId);
        if (index) instanceRef.current?.moveToIdx(index);
    }, [selectedId, listSelectItem]);

    const [sliderRef, instanceRef] = useKeenSlider({
        loop: true,
        mode: 'free-snap',
        slides: {
            perView: 'auto',
            spacing: 15
        }
    });

    return (
        <>
            {loading || !listSelectItem.length ? (
                <Spin spinning={loading}>
                    <img src={defaultLogo} className="block mx-auto h-28" />
                </Spin>
            ) : (
                <div ref={sliderRef} className="keen-slider">
                    {listSelectItem.map((item) => (
                        <Card
                            className={`keen-slider__slide min-w-fit h-28 px-3 cursor-pointer whitespace-nowrap border-gray-300 hover:border-sky-700 flex justify-center items-center text-base shadow-md border rounded ${
                                item.id === selectedId && 'bg-sky-700 text-white font-medium'
                            }`}
                            onClick={() => onSelected(item.id)}
                            key={item.id}
                        >
                            {item.content}
                        </Card>
                    ))}
                </div>
            )}
        </>
    );
};

FDSliderSelection.propTypes = {
    selectedId: PropTypes.string,
    listSelectItem: PropTypes.array,
    loading: PropTypes.bool,
    onSelected: PropTypes.func
};

export default FDSliderSelection;
