import { Select } from 'antd';
import './FDSelection.scss';

const FDSelection = ({
    onChange,
    listSelectItem = [],
    className,
    placeholder,
    defaultValue,
    value,
    showSearch,
    mode,
    disabled = false,
    dataTestId,
    loading = false,
    allowClear = false
}) => {
    function handleChange(value) {
        if (onChange) {
            onChange(
                value,
                listSelectItem.find((item) => item.id === value)
            );
        }
    }

    return (
        <Select
            mode={mode}
            placeholder={placeholder || 'Please select'}
            onChange={handleChange}
            defaultValue={defaultValue}
            value={value}
            className={className}
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear={allowClear}
            showSearch={showSearch}
            showArrow
            disabled={disabled}
            loading={loading}
        >
            {listSelectItem.map((item) => (
                <Select.Option
                    key={item.id}
                    value={item.id}
                    disabled={item.disabled}
                    data-testid={dataTestId}
                >
                    {item.text}
                </Select.Option>
            ))}
        </Select>
    );
};

export default FDSelection;
