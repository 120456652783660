import { Card, Spin } from 'antd';
import { GRAY, GREEN, ORANGE, RED, STRESS_ZONES, YELLOW } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds } from 'common/utils';
import { FDBar } from 'components';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { processStressEventSelector } from 'redux/selector';

const StressZone = () => {
    const { t } = useTranslation();
    const { data: stressEvent, loading } = useSelector(processStressEventSelector);

    const stressZones = map(STRESS_ZONES, (stressZone) => ({
        name: t(stressZone.name),
        value: stressEvent.stressLevels
            ?.filter(
                (stressLevel) =>
                    stressZone.min <= stressLevel.level && stressLevel.level <= stressZone.max
            )
            .reduce(
                (accumulator, stressLevel) =>
                    accumulator +
                    getDifferenceInSeconds(stressLevel.start_time, stressLevel.stop_time),
                0
            )
    }));

    return (
        <Spin spinning={loading}>
            <Card className="mt-2">
                <p className="font-medium text-base">{t('process.stressZone')}</p>
                <FDBar
                    data={stressZones}
                    colors={[GRAY, RED, ORANGE, YELLOW, GREEN]}
                    height={300}
                    formatValue={formatSeconds}
                    chartConfig={{
                        animation: false
                    }}
                />
            </Card>
        </Spin>
    );
};

export default StressZone;
