import { Spin } from 'antd';
import fishwellLogo from 'assets/Fishwell_Logo.png';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { stressDetectionServiceBoatsSelector } from 'redux/selector';
import { resetServiceBoats } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getServiceBoats } from 'redux/thunks';
import PenList from './PenList';
import ServiceBoatStatus from './ServiceBoatStatus';
import { useFlags } from 'launchdarkly-react-client-sdk';
import NotFound from 'pages/not-found';

const StressDetection = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { showLiveCounting } = useFlags();

    const [penListState, setPenListState] = useState({ isOpen: false, serviceBoat: null });
    const { data: serviceBoats, loading } = useSelector(stressDetectionServiceBoatsSelector);

    useEffect(() => {
        dispatch(getServiceBoats());

        return () => {
            dispatch(resetServiceBoats());
        };
    }, []);

    const handlePenListClose = () => {
        setPenListState({ isOpen: false, serviceBoat: null });
    };

    const handlePenListOpen = (serviceBoat) => {
        setPenListState({ isOpen: true, serviceBoat });
    };

    if (!showLiveCounting) {
        return <NotFound />;
    }

    return (
        <div className="counting-page">
            <div className="flex justify-center mb-8">
                <img
                    src={fishwellLogo}
                    alt="fishwell-logo"
                    className="pl-10 xs:w-3/4 md:w-2/3 xl:w-1/3"
                />
            </div>

            <div className="mt-10">
                <h2>{t('stressDetection.serviceBoatStatus')}</h2>

                {loading ? (
                    <Spin />
                ) : (
                    <div className="flex flex-wrap gap-5">
                        {serviceBoats.map((boat) => (
                            <ServiceBoatStatus
                                serviceBoat={boat}
                                key={boat.id}
                                onPenListOpen={handlePenListOpen}
                            />
                        ))}
                    </div>
                )}
            </div>

            <PenList isOpen={penListState.isOpen} onClose={handlePenListClose} />
        </div>
    );
};

export default StressDetection;
