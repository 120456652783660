import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Skeleton, Spin } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getDeadFishMortalityCard } from 'redux/thunks';

const FDFishMortalityCard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { siteId, penId } = useParams();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data, loading } = useSelector((s) => s.deadFishMortalityCard);

    useEffect(() => {
        dispatch(
            getDeadFishMortalityCard({
                siteIds: [siteId],
                penIds: [penId],
                fromDate: initialFilters.timePeriod.fromDate
            })
        );
    }, [siteId, penId, initialFilters.timePeriod.fromDate]);

    return (
        <Spin spinning={loading}>
            <div className="site-statistic-demo-card fish-mortality-card">
                <Row className="bg-white rounded">
                    <Skeleton loading={loading && !data.length} active />
                </Row>

                {data.map((item, index) => {
                    return (
                        <div key={index}>
                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Card className="h-full mortality-count">
                                        <div className="w-full h-full flex justify-center xs:flex-col lg:flex-row lg:space-x-16 xl:flex-col xl:space-x-0 xxl:flex-row xxl:space-x-16">
                                            <div className="title flex justify-center items-center">
                                                <span className="text-lg text-gray-600 font-semibold">
                                                    {t(
                                                        'dashboard.fishMortality.mortalityCard.mortalityCount'
                                                    )}
                                                </span>
                                            </div>

                                            <div className="h-full flex justify-center align-center">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-right">
                                                                <span className="text-gray-500 text-base py-1 mr-1">
                                                                    {t(
                                                                        'general.fishMortality.productionFish'
                                                                    ) + ':'}
                                                                </span>
                                                            </td>
                                                            <td className="text-left">
                                                                <span className="font-semibold text-xl">
                                                                    {item.currentCount}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-right">
                                                                <span className="text-gray-500 text-base py-1 mr-1">
                                                                    {t(
                                                                        'general.fishMortality.cleansingFish'
                                                                    ) + ':'}
                                                                </span>
                                                            </td>
                                                            <td className="text-left">
                                                                <span className="font-semibold text-xl">
                                                                    {item.cleansingFishCurrentCount}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Card className="mortality-trend h-full">
                                        <div className="w-full flex justify-center items-center xs:flex-col lg:flex-row lg:space-x-16 xl:flex-col xl:space-x-0 xxl:flex-row xxl:space-x-16">
                                            <div className="title flex justify-center items-center">
                                                <span className="text-lg text-gray-600 font-semibold">
                                                    {t(
                                                        'dashboard.fishMortality.mortalityCard.mortalityTrend'
                                                    )}
                                                </span>
                                            </div>

                                            <div className="data flex xs:space-x-4 sm:space-x-16">
                                                <div className="flex flex-col items-center">
                                                    <span className="text-gray-500 text-base mr-1">
                                                        {t('general.fishMortality.productionFish')}
                                                    </span>
                                                    <span
                                                        className={`text-xl font-semibold text-gray-500 ${
                                                            item.rate > 0
                                                                ? 'text-red-600'
                                                                : 'text-green-700'
                                                        }`}
                                                    >
                                                        {item.rate > 0 ? (
                                                            <ArrowUpOutlined />
                                                        ) : (
                                                            <ArrowDownOutlined />
                                                        )}
                                                        {Math.abs(item.rate)}%
                                                    </span>
                                                </div>

                                                <div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-right">
                                                                    <span className="text-gray-500 text-base py-1 mr-1">
                                                                        {t(
                                                                            'dashboard.fishMortality.mortalityCard.lastPeriod'
                                                                        ) + ':'}
                                                                    </span>
                                                                </td>
                                                                <td className="text-left">
                                                                    <span className="font-semibold text-xl">
                                                                        {item.previousCount}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-right">
                                                                    <span className="text-gray-500 text-base py-1 mr-1">
                                                                        {t(
                                                                            'dashboard.fishMortality.mortalityCard.thisPeriod'
                                                                        ) + ':'}
                                                                    </span>
                                                                </td>
                                                                <td className="text-left">
                                                                    <span className="font-semibold text-xl">
                                                                        {item.currentCount}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </div>
        </Spin>
    );
};

export default FDFishMortalityCard;
