import { Card, Col, Row, Spin, Statistic } from 'antd';
import { BLUE, RED } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDStackedColumn } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getReportSalmonWound } from 'redux/thunks';
import './index.scss';

const SalmonWound = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { expandRowMortalityReport } = useFlags();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data, loading } = useSelector((s) => s.reportSalmonWound);

    useEffect(() => {
        dispatch(
            getReportSalmonWound({
                siteIds: initialFilters.siteIds,
                penIds: initialFilters.penIds,
                fishGroupIds: initialFilters.fishGroupIds,
                fromDate: initialFilters.fromDate,
                toDate: initialFilters.toDate
            })
        );
    }, [
        initialFilters.siteIds,
        initialFilters.penIds,
        initialFilters.fishGroupIds,
        initialFilters.fromDate,
        initialFilters.toDate
    ]);

    return (
        expandRowMortalityReport && (
            <div className="salmon-wound-report mt-5">
                <h2 className="mb-2">{t('mortalityAnalysis.salmonWound.title')}</h2>
                <Spin spinning={loading}>
                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Row gutter={[8, 8]} className="h-full">
                                {data.counts &&
                                    data.counts.map((item, index) => {
                                        return (
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={24}
                                                xl={24}
                                                key={index}
                                                className={item.title}
                                            >
                                                <Card className="flex justify-center content-center h-full card-count">
                                                    <Statistic
                                                        title={t(
                                                            `mortalityAnalysis.salmonWound.card.labels.${item.title}`
                                                        )}
                                                        value={item.totalCount}
                                                        valueStyle={{
                                                            color: '#cf1322'
                                                        }}
                                                    />
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                            <Card>
                                <FDStackedColumn
                                    data={data.charts?.flatMap((item) => [
                                        {
                                            name: formatDateTime(item.date),
                                            value: item.numberOfFishWithWounds,
                                            type: t(
                                                'mortalityAnalysis.salmonWound.chart.labels.withWounds'
                                            )
                                        },
                                        {
                                            name: formatDateTime(item.date),
                                            value: item.numberOfFishWithoutWounds,
                                            type: t(
                                                'mortalityAnalysis.salmonWound.chart.labels.withoutWounds'
                                            )
                                        }
                                    ])}
                                    colors={[RED, BLUE]}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </div>
        )
    );
};

SalmonWound.propTypes = {
    className: PropTypes.string
};

export default SalmonWound;
