import { Card, Col, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SITE_NOTIFICATION_EXPLANATION } from 'common/constants';
import { getMortalityStatus } from 'common/utils';
import { FDGauge, FDTooltip } from 'components';
import { useAppDispatch } from 'redux/store';
import { getSitesNotifications } from 'redux/thunks';

const SiteNotification = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: sitesNotification, loading } = useSelector((s) => s.sitesNotification);

    useEffect(() => {
        dispatch(
            getSitesNotifications({
                date: initialFilters.daily.fromDate,
                siteIds: [initialFilters.daily.siteId]
            })
        );
    }, [initialFilters.daily]);

    return (
        <Spin spinning={loading}>
            {!sitesNotification[0]?.siteName ? (
                <Card className="text-center">
                    <img alt="ava" src={require('assets/Empty_Box.png')} />
                    <p className="text-neutral-300">No Data</p>
                </Card>
            ) : (
                <Card>
                    <div className="flex justify-end">
                        <FDTooltip explainInfo={SITE_NOTIFICATION_EXPLANATION} />
                    </div>

                    <Row gutter={24}>
                        <Col
                            xxl={5}
                            xl={8}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="flex flex-col justify-center items-center pb-8"
                        >
                            <span className="font-semibold text-xl">
                                Site {sitesNotification[0]?.siteName}
                            </span>
                            <FDGauge
                                width={280}
                                height={180}
                                percent={sitesNotification[0]?.deviation}
                            />
                            <div className="flex justify-center mt-4 text-base">
                                <table>
                                    <tbody>
                                        <tr className="bg-slate">
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base mr-2">
                                                    {t('dailyReport.mortalityStatistics.mortality')}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl text-left">
                                                <span> {sitesNotification[0]?.totalCount}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">
                                                <span className="text-gray-500 mr-2">
                                                    {t(
                                                        getMortalityStatus(
                                                            sitesNotification[0]?.deviation
                                                        )
                                                    )}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl text-left">
                                                <span>
                                                    {Math.round(
                                                        sitesNotification[0]?.deviation * 100
                                                    )}
                                                    %
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>

                        <Col xxl={19} xl={16} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[16, 16]}>
                                {sitesNotification[0]?.notificationPens?.map((pen, index) => {
                                    return (
                                        <Col
                                            xxl={4}
                                            xl={8}
                                            lg={8}
                                            md={12}
                                            sm={8}
                                            xs={12}
                                            className="text-center"
                                            key={`penData-${index}`}
                                        >
                                            <span className="font-semibold text-base">
                                                Pen {pen.penNumber}
                                            </span>
                                            <FDGauge
                                                height={90}
                                                percent={pen.deviation}
                                                isSmall={true}
                                            />
                                            <div className="bg-slate text-[15px]">
                                                <div>
                                                    <span className="mr-2 text-gray-500">
                                                        {t(getMortalityStatus(pen.deviation))}
                                                    </span>
                                                    <span className="font-semibold">
                                                        {Math.round(pen.deviation * 100)}%
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="mr-2 text-gray-500">
                                                        {t(
                                                            'dailyReport.mortalityStatistics.contribution'
                                                        ) + ':'}
                                                    </span>
                                                    <span className="font-semibold">
                                                        {Math.round(pen.contribution * 100)}%
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="mr-2 text-gray-500">
                                                        {t(
                                                            'dailyReport.mortalityStatistics.counted'
                                                        )}
                                                    </span>
                                                    <span className="font-semibold ">
                                                        {!pen.isActive
                                                            ? 'Not Active'
                                                            : pen.totalCount > 0
                                                            ? 'Ok'
                                                            : 'No'}
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Card>
            )}
        </Spin>
    );
};

export default SiteNotification;
