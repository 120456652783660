import { Button, Modal, Spin } from 'antd';
import { SUCCESSFULLY_EDIT_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import FDTreeTransfer from 'components/common/TreeTransfer/FDTreeTransfer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getSitesContainingPens, getUsers, updateUser } from 'redux/thunks';

const UserForm = ({ open = false, user, onClose = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const updateLoading = useSelector((s) => s.user.loading);
    const { data: sites } = useSelector((s) => s.siteContainingPens);

    // data for loading site and pen to form assign site/pen
    const [penIds, setPenIds] = useState([]);

    useEffect(() => {
        dispatch(getSitesContainingPens());
    }, []);

    useEffect(() => {
        setPenIds(user?.sites?.flatMap((site) => site.pens?.map((pen) => pen.id)));
    }, [user]);

    const handleUpdateUser = async () => {
        try {
            await dispatch(updateUser({ id: user.id, item: { penIds } })).unwrap();

            dispatch(getUsers());
            alertSuccessMessage(SUCCESSFULLY_EDIT_MESSAGE);
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }
    };

    return (
        <Modal
            title={t('user.userForm.assign.title')}
            open={open}
            onCancel={() => onClose()}
            width={500}
            footer={
                <>
                    <Button type="default" onClick={() => onClose()} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={handleUpdateUser}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={updateLoading}>
                <FDTreeTransfer
                    treeData={sites
                        .filter((site) => site.pens.length)
                        .map((site) => ({
                            key: site.id,
                            title: site.siteName,
                            children: site.pens.map((pen) => ({
                                key: pen.id,
                                title: pen.penNumber
                            }))
                        }))}
                    selectedKeys={penIds}
                    onSelectedKeysChange={(selectedKeys) => setPenIds(selectedKeys)}
                    messageForEmptyTreeData={t('message.noSiteAndPen')}
                />
            </Spin>
        </Modal>
    );
};

export default UserForm;
