import { Button, Card } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_SELECTED, BUTTON_BLUE_UN_SELECTED, EFFICIENCY_TOGGLE } from 'common/constants';
import { formatNumberToFixedDigits, formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { flatMap, reduce, chain } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { channelsSelector, eventDetailStartTimeSelector } from 'redux/selector';

const TotalEfficiency = ({ sliderValues }) => {
    const { t } = useTranslation();

    const channels = useSelector(channelsSelector);
    const eventStartTime = useSelector(eventDetailStartTimeSelector);

    const [toggleState, setToggleState] = useState(EFFICIENCY_TOGGLE.KG_PER_30_SECONDS);
    const [chartData, setChartData] = useState([]);
    const [tonPerHourChartData, setTonPerHourChartData] = useState([]);

    useEffect(() => {
        const filteredData = flatMap(channels, (channel) => {
            return reduce(
                channel.totalEfficiencies,
                (accumulator, totalEfficiency) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        eventStartTime,
                        totalEfficiency.stop_time
                    );

                    if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                        accumulator.push({
                            type: `${t('eventDetail.channel')} ${channel.channelName}`,
                            name: formatSeconds(differenceInSeconds),
                            value: totalEfficiency.value || 0
                        });

                    return accumulator;
                },
                []
            );
        });

        let differenceInSeconds = getDifferenceInSeconds(
            eventStartTime,
            channels[0]?.totalEfficiencies[0]?.stop_time
        );

        const groups = chain(filteredData)
            .groupBy('type')
            .map((value, key) => ({ type: key, values: value }))
            .value();

        const groupedData = flatMap(groups, (group) => {
            return groupEfficiencyPerHour(group.type, group.values, differenceInSeconds);
        });

        setChartData(filteredData);
        setTonPerHourChartData(groupedData);
    }, [sliderValues]);

    const handleToggleStateChange = (state) => {
        setToggleState(state);

        appInsights.trackEvent({
            name: 'Event total efficiency toggle state change',
            properties: { filterBy: state }
        });
    };

    const groupEfficiencyPerHour = (channelName, totalEfficiencies, differenceInSeconds) => {
        const TOTAL_SECONDS_PER_HOUR = 3600;
        const result = [];

        let hour = 0;

        let groupSize = TOTAL_SECONDS_PER_HOUR / differenceInSeconds;
        for (let i = 0; i < totalEfficiencies.length; i += groupSize) {
            const group = totalEfficiencies.slice(i, i + groupSize);
            const sum = group.reduce((acc, item) => acc + item.value, 0);
            hour++;
            result.push({
                type: `${channelName}`,
                name: formatSeconds(hour * TOTAL_SECONDS_PER_HOUR),
                value: formatNumberToFixedDigits(sum / 1000)// convert Kg to Ton
            });
        }

        return result;
    };

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.totalEfficiency')}</p>
            <div className="flex justify-end">
                <Button
                    className={
                        toggleState === EFFICIENCY_TOGGLE.KG_PER_30_SECONDS
                            ? BUTTON_BLUE_SELECTED
                            : BUTTON_BLUE_UN_SELECTED
                    }
                    onClick={() => handleToggleStateChange(EFFICIENCY_TOGGLE.KG_PER_30_SECONDS)}
                    style={{ marginRight: '4px' }}
                >
                    {t('eventDetail.kgPer30Seconds')}
                </Button>

                <Button
                    className={
                        toggleState === EFFICIENCY_TOGGLE.TON_PER_HOUR
                            ? BUTTON_BLUE_SELECTED
                            : BUTTON_BLUE_UN_SELECTED
                    }
                    onClick={() => handleToggleStateChange(EFFICIENCY_TOGGLE.TON_PER_HOUR)}
                >
                    {t('eventDetail.tonPerHour')}
                </Button>
            </div>
            <FDLine
                data={
                    toggleState === EFFICIENCY_TOGGLE.KG_PER_30_SECONDS
                        ? chartData
                        : tonPerHourChartData
                }
                height={300}
                chartConfig={{ animation: false }}
            />
        </Card>
    );
};

TotalEfficiency.propTypes = {
    sliderValues: PropTypes.array
};

export default TotalEfficiency;
