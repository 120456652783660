import { Card, Col, Row } from 'antd';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AboveAverageBell from './AboveAverageBell';
import './FDItemList.scss';
import { appInsights } from 'AppInsights';

const FDItemList = ({ title = '', loading = false, itemList = [] }) => {
    const { t } = useTranslation();
    const { notificationSwitch } = useFlags();
    const navigate = useNavigate();

    const handleItemClick = (item) => {
        if (!item.hasData) return;

        navigate(item.link);
        appInsights.trackEvent({
            name: 'Site/Pen at bottom click',
            properties: { name: item.name }
        });
    };

    return (
        <div>
            <h2 className="mb-2">{title}</h2>

            <Card bordered loading={loading} className="item-list">
                <Row gutter={[8, 8]}>
                    {itemList.map((item) => (
                        <Col
                            xs={12}
                            sm={8}
                            md={8}
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="item"
                            key={item.id}
                        >
                            <Card
                                className={`item flex justify-center items-center font-medium text-base rounded h-28 text-white relative 
                                        ${
                                            item.hasData && !item.hasViewed
                                                ? 'hover:bg-slate-700 cursor-pointer bg-slate-600'
                                                : !item.hasData
                                                ? 'bg-slate-300'
                                                : 'hover:bg-stone-500 cursor-pointer bg-stone-400'
                                        }
                                        ${item.isCurrentItem && 'border-t-8 border-t-sky-600'}
                                    `}
                                onClick={() => handleItemClick(item)}
                            >
                                {notificationSwitch && (
                                    <AboveAverageBell hasNotification={item.hasNotification} />
                                )}

                                <div>
                                    <div>{item.name}</div>
                                    {item.fishGroups?.map((fishGroup) => (
                                        <div key={fishGroup.fishGroupId}>
                                            {fishGroup.fishGroupId}-{fishGroup.sibling}
                                        </div>
                                    ))}

                                    {!item.hasData && (
                                        <p className={`btn-contact`}>{t('general.contactUs')}</p>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Card>
        </div>
    );
};

export default FDItemList;
