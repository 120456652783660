import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { mortalityDetailSelector, mortalityListSelector } from 'redux/selector';
import { useAppDispatch } from 'redux/store';
import { getMortalityDetail } from 'redux/thunks';
import MortalityCount from './MortalityCount';
import MortalityInfo from './MortalityInfo';
import WoundAttachment from './WoundAttachment';
import WoundCount from './WoundCount';
import { Spin } from 'antd';

const MortalityDetail = () => {
    const dispatch = useAppDispatch();

    const { data: mortalities } = useSelector(mortalityListSelector);
    const { loading } = useSelector(mortalityDetailSelector);
    const initialFilters = useSelector((s) => s.filter.data);

    useEffect(() => {
        if (initialFilters.selectedMortalityId) {
            var mortalityIds = mortalities.find(
                (mortality) => mortality.id === initialFilters.selectedMortalityId
            )?.ids;
            dispatch(getMortalityDetail({ ids: mortalityIds }));
        }
    }, [initialFilters.selectedMortalityId]);

    return (
        <Spin spinning={loading}>
            <Row gutter={8}>
                <Col xs={24}>
                    <MortalityInfo />
                </Col>
                <Col xs={24} xl={8}>
                    <MortalityCount />
                </Col>
                <Col xs={24} xl={16}>
                    <WoundCount />
                </Col>
                <Col xs={24}>
                    <WoundAttachment />
                </Col>
            </Row>
        </Spin>
    );
};

export default MortalityDetail;
