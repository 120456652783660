import { createSlice } from '@reduxjs/toolkit';
import { getScoringEventDetail, getScoringEventList } from 'redux/thunks';

const initialState = {
    list: {
        data: [],
        loading: false
    },
    detail: {
        data: {},
        loading: false
    }
};

export const scoringEventSlice = createSlice({
    name: 'scoringEventSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [getScoringEventList.pending]: (state) => {
            state.list.loading = true;
        },
        [getScoringEventList.fulfilled]: (state, action) => {
            state.list.loading = false;
            state.list.data = action.payload || initialState.list.data;
        },
        [getScoringEventList.rejected]: (state) => {
            state.list.loading = false;
            state.list.data = initialState.list.data;
        },
        [getScoringEventDetail.pending]: (state) => {
            state.detail.loading = true;
        },
        [getScoringEventDetail.fulfilled]: (state, action) => {
            state.detail.loading = false;
            state.detail.data = action.payload || initialState.detail.data;
        },
        [getScoringEventDetail.rejected]: (state) => {
            state.detail.loading = false;
            state.detail.data = initialState.detail.data;
        }
    }
});
