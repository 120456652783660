import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
    companyConfigDetailSlice,
    companyConfigSlice,
    companyNotificationSlice,
    correlationAnalysisSlice,
    dailyEventSlice,
    dailyMortalitySlice,
    dailyReportCountingSlice,
    deadFishCountingMortalitySlice,
    deadFishMortalityCardSlice,
    deadFishMortalitySlice,
    engineBoatSelectionSlice,
    engineSiteSelectionSlice,
    engineSiteSlice,
    engineSlice,
    engineStatusSlice,
    eventChartSlice,
    eventDeadFishCountingMortalitySlice,
    eventDetailSlice,
    eventFishHealthSlice,
    eventListSlice,
    eventNoteSlice,
    filterSlice,
    fishGroupDeadFishMortalitySlice,
    fishGroupEventListSlice,
    fishGroupFishHealthSlice,
    fishGroupMortalitySlice,
    fishGroupReportTableSlice,
    fishGroupSlice,
    fishGroupTreeByFishwellFishGroupIdSlice,
    fishwellFishIdSelectionSlice,
    flowSlice,
    localeSlice,
    mortalitySlice,
    notificationSlice,
    originalSiteSelectionSlice,
    otherDeadFishCountSlice,
    penComparisonSlice,
    penDetailSlice,
    penSelectionSlice,
    penSlice,
    pensNotificationSlice,
    processDetailSlice,
    profileSlice,
    reportSalmonWoundSlice,
    reportTableSlice,
    scoringEventSlice,
    serviceBoatSelectionSlice,
    serviceSlice,
    siteComparisonSlice,
    siteContainingPensSlice,
    siteDetailSlice,
    siteSelectionSlice,
    siteSlice,
    sitesNotificationSlice,
    stockCountSlice,
    stressAnalysisSlice,
    stressDetectionSlice,
    tenantSlice,
    userSlice
} from './slices';

const rootReducer = combineReducers({
    companyConfig: companyConfigSlice.reducer,
    companyConfigDetail: companyConfigDetailSlice.reducer,
    penComparison: penComparisonSlice.reducer,
    siteComparison: siteComparisonSlice.reducer,
    dailyEvent: dailyEventSlice.reducer,
    dailyMortality: dailyMortalitySlice.reducer,
    dailyReportCounting: dailyReportCountingSlice.reducer,
    engineSite: engineSiteSlice.reducer,
    engine: engineSlice.reducer,
    engineStatus: engineStatusSlice.reducer,
    eventChart: eventChartSlice.reducer,
    eventDeadFishCountingMortality: eventDeadFishCountingMortalitySlice.reducer,
    eventDetail: eventDetailSlice.reducer,
    eventList: eventListSlice.reducer,
    eventNote: eventNoteSlice.reducer,
    eventFishHealth: eventFishHealthSlice.reducer,
    penDetail: penDetailSlice.reducer,
    pen: penSlice.reducer,
    deadFishMortalityCard: deadFishMortalityCardSlice.reducer,
    deadFishCountingMortality: deadFishCountingMortalitySlice.reducer,
    deadFishMortality: deadFishMortalitySlice.reducer,
    fishGroupMortality: fishGroupMortalitySlice.reducer,
    reportSalmonWound: reportSalmonWoundSlice.reducer,
    reportTable: reportTableSlice.reducer,
    stockCount: stockCountSlice.reducer,
    engineBoatSelection: engineBoatSelectionSlice.reducer,
    engineSiteSelection: engineSiteSelectionSlice.reducer,
    originalSiteSelection: originalSiteSelectionSlice.reducer,
    otherDeadFishCount: otherDeadFishCountSlice.reducer,
    penSelection: penSelectionSlice.reducer,
    siteSelection: siteSelectionSlice.reducer,
    serviceBoatSelection: serviceBoatSelectionSlice.reducer,
    siteContainingPens: siteContainingPensSlice.reducer,
    siteDetail: siteDetailSlice.reducer,
    site: siteSlice.reducer,
    filter: filterSlice.reducer,
    notification: notificationSlice.reducer,
    companyNotification: companyNotificationSlice.reducer,
    sitesNotification: sitesNotificationSlice.reducer,
    pensNotification: pensNotificationSlice.reducer,
    profile: profileSlice.reducer,
    user: userSlice.reducer,
    service: serviceSlice.reducer,
    fishGroupDeadFishMortality: fishGroupDeadFishMortalitySlice.reducer,
    fishGroupEventList: fishGroupEventListSlice.reducer,
    fishGroupFishHealth: fishGroupFishHealthSlice.reducer,
    fishGroupReportTable: fishGroupReportTableSlice.reducer,
    fishGroup: fishGroupSlice.reducer,
    fishGroupTreeByFishwellFishGroupId: fishGroupTreeByFishwellFishGroupIdSlice.reducer,
    fishwellFishIdSelection: fishwellFishIdSelectionSlice.reducer,
    locale: localeSlice.reducer,
    tenant: tenantSlice.reducer,
    mortality: mortalitySlice.reducer,
    stressDetection: stressDetectionSlice.reducer,
    stressAnalysis: stressAnalysisSlice.reducer,
    correlationAnalysis: correlationAnalysisSlice.reducer,
    flow: flowSlice.reducer,
    scoringEvent: scoringEventSlice.reducer,
    processDetail: processDetailSlice.reducer
});

const setupStore = (preloadedState) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false // Disable serializable check
            }),
        devTools: process.env.NODE_ENV !== 'production'
    });
};

export const useAppDispatch = () => useDispatch();

export default setupStore;
