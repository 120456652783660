import axios from 'axios';
import qs from 'qs';
import { getToken } from '../Auth';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        accept: 'application/json',
        contentType: 'application/json',
        Authorization: ''
    }
};

const get = (url, params = {}, options = {}) => {
    if (!url) return;

    return instance.get(url, {
        ...defaultOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const interceptorHandleRequest = async (config) => {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    return Promise.reject(error.response.data.errors || error.response.data.message);
};

instance.interceptors.request.use(interceptorHandleRequest, handleError);
instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { get };
