export const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
};

export const blockInvalidChar = (e) => {
    return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
};

export const generateRandomFishGroupId = () => {
    const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';

    let result = '';

    const getRandomChar = (sourceString) => {
        const array = new Uint8Array(1);
        window.crypto.getRandomValues(array);
        return sourceString[array[0] % sourceString.length];
    };

    result += getRandomChar(upperLetters);

    for (let i = 0; i < 2; i++) {
        result += getRandomChar(letters);
    }

    for (let i = 0; i < 3; i++) {
        result += getRandomChar(numbers);
    }

    return result;
};
