import { Card, Spin } from 'antd';
import { BLUE, DARK_GREEN, GREEN, ORANGE } from 'common/constants';
import { FDColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { processFlowDetectionSelector } from 'redux/selector';

const Flow = () => {
    const { t } = useTranslation();
    const { data: flow, loading } = useSelector(processFlowDetectionSelector);

    var flowDetections = flow.fishes?.map((fish) => ({
        name: t(`general.fishMortality.${fish.fishName}`),
        type: t(`general.fishMortality.${fish.fishName}`),
        value: fish.count
    }));

    return (
        <Spin spinning={loading}>
            <Card className="mt-2">
                <p className="font-medium text-base">{t('process.flow')}</p>
                <FDColumn
                    data={flowDetections}
                    color={[ORANGE, GREEN, DARK_GREEN, BLUE]}
                    height={300}
                />
            </Card>
        </Spin>
    );
};

export default Flow;
