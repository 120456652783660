import { formatNumberToFixedDigits } from 'common/utils';
import { FDTable } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ScoringPerFish = () => {
    const { t } = useTranslation();

    const { data: scoringEvent, loading } = useSelector((s) => s.scoringEvent.detail);

    const columns = [
        { title: t('scoringEvent.numberOfFish'), dataIndex: 'numberOfFish' },
        {
            title: t('scoringEvent.bev_of_lice'),
            dataIndex: 'bev_of_lice',
            sorter: {
                compare: (a, b) => a.bev_of_lice - b.bev_of_lice,
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.fast_of_lice'),
            dataIndex: 'fast_of_lice',
            sorter: {
                compare: (a, b) => a.fast_of_lice - b.fast_of_lice,
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.kjm_of_lice'),
            dataIndex: 'kjm_of_lice',
            sorter: {
                compare: (a, b) => a.kjm_of_lice - b.kjm_of_lice,
                multiple: 3
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.eye_injuries'),
            dataIndex: 'eye_injuries',
            sorter: {
                compare: (a, b) => a.eye_injuries - b.eye_injuries,
                multiple: 4
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.fid_damage'),
            dataIndex: 'fid_damage',
            sorter: {
                compare: (a, b) => a.fid_damage - b.fid_damage,
                multiple: 5
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.gill_bleeding'),
            dataIndex: 'gill_bleeding',
            sorter: {
                compare: (a, b) => a.gill_bleeding - b.gill_bleeding,
                multiple: 6
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.mouth_injuries'),
            dataIndex: 'mouth_injuries',
            sorter: {
                compare: (a, b) => a.mouth_injuries - b.mouth_injuries,
                multiple: 7
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.shell_loss'),
            dataIndex: 'shell_loss',
            sorter: {
                compare: (a, b) => a.shell_loss - b.shell_loss,
                multiple: 8
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.skin_bleeding'),
            dataIndex: 'skin_bleeding',
            sorter: {
                compare: (a, b) => a.skin_bleeding - b.skin_bleeding,
                multiple: 9
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('scoringEvent.wound'),
            dataIndex: 'wound',
            sorter: {
                compare: (a, b) => a.wound - b.wound,
                multiple: 10
            },
            sortDirections: ['ascend', 'descend']
        }
    ];

    const scoringPerFishes = scoringEvent.scoringPerFishes?.flatMap((scoringPerFish) =>
        scoringPerFish.properties?.reduce((acc, { name, value }) => {
            acc[name] = formatNumberToFixedDigits(value);
            acc.numberOfFish = scoringPerFish.numberOfFish;
            return acc;
        }, {})
    );

    return (
        <div className="mt-2">
            <FDTable headers={columns} data={scoringPerFishes} loading={loading} />
        </div>
    );
};

export default ScoringPerFish;
