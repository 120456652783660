import { FDDialogBox } from 'components';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import React, { useEffect, useState } from 'react';

const ConfirmDialog = ({ show }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

    useEffect(() => {
        setShowDialog(show);
    }, [show]);

    return (
        <FDDialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
        />
    );
};

export default ConfirmDialog;
