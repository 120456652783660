import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    DAILY_EVENT_API,
    DAILY_MORTALITY_API,
    DEAD_FISH_MORTALITY_CARD_API
} from 'common/constants';
import moment from 'moment';
import { API } from 'services';

export const getDailyMortality = createAsyncThunk(
    'dailyReports/getDailyMortality',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(DAILY_MORTALITY_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateDailyMortality = createAsyncThunk(
    'dailyReports/updateDailyMortality',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.put(DAILY_MORTALITY_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getDeadFishDailyCounting = createAsyncThunk(
    'dailyReports/getDeadFishDailyCounting',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(DEAD_FISH_MORTALITY_CARD_API, {
                ...filters,
                fromDate: moment().startOf('day'),
                toDate: moment().endOf('day')
            });
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getDailyEvent = createAsyncThunk(
    'dailyReports/getDailyEvent',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(DAILY_EVENT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
