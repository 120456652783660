import { Card } from 'antd';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { flatMap } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { channelsSelector, eventDetailStartTimeSelector } from 'redux/selector';

const NumberOfFish = ({ sliderValues }) => {
    const { t } = useTranslation();

    const channels = useSelector(channelsSelector);
    const eventStartTime = useSelector(eventDetailStartTimeSelector);

    const [numberOfFish, setNumberOfFish] = useState([]);

    useEffect(() => {
        const filteredData = flatMap(channels, (channel) => {
            var previousNumberFish;
            return channel.numberFishes
                ?.map((numberFish) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        eventStartTime,
                        numberFish.stop_time
                    );

                    const newNumberFish = {
                        type: t('eventDetail.channel') + ' ' + channel.channelName,
                        name: formatSeconds(differenceInSeconds),
                        differenceInSeconds: differenceInSeconds,
                        value:
                            numberFish.value &&
                            previousNumberFish &&
                            numberFish.value > previousNumberFish
                                ? numberFish.value - previousNumberFish
                                : 0
                    };
                    previousNumberFish = numberFish.value;

                    return newNumberFish;
                })
                .filter((numberFish) =>
                    isTimeWithinSliderValues(sliderValues, numberFish.differenceInSeconds)
                );
        });

        setNumberOfFish(filteredData);
    }, [sliderValues]);

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.numberOfFish')}</p>
            <FDLine data={numberOfFish} height={300} chartConfig={{ animation: false }} />
        </Card>
    );
};

NumberOfFish.propTypes = {
    sliderValues: PropTypes.array
};

export default NumberOfFish;
