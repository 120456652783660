import { Line } from '@ant-design/plots';
import PropTypes from 'prop-types';

const FDLine = ({
    isSingleLine = false,
    data = [],
    chartConfig = {},
    slider,
    color,
    height,
    style
}) => {
    const config = {
        height: height,
        data,
        xField: 'name',
        yField: 'value',
        seriesField: !isSingleLine && 'type',
        smooth: true,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 2000
            }
        },
        legend: {
            position: 'top'
        },
        color: color,
        ...chartConfig,
        xAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            },
            ...chartConfig.xAxis
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            },
            ...chartConfig.yAxis
        },
        slider
    };

    return <Line {...config} style={style} />;
};

FDLine.propTypes = {
    isSingleLine: PropTypes.bool,
    data: PropTypes.array,
    chartConfig: PropTypes.object,
    slider: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.number,
    style: PropTypes.object
};

export default FDLine;
