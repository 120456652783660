import { BellFilled } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

const FDNotificationBell = ({ count = null, dot = false, ...props }) => {
    return (
        <Badge count={count > 9 ? '9+' : count} size="small" dot={dot} {...props}>
            <BellFilled size="large" style={{ fontSize: '22px', color: '#334155' }} />
        </Badge>
    );
};

export default FDNotificationBell;
