import { FDSwitching } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ENGINE_SERVICE } from 'services';

const StreamingVideo = () => {
    const { t } = useTranslation();

    const [isVisible, setIsVisible] = useState(false);
    const [streamingVideoUrl, setStreamingVideoUrl] = useState(null);

    useEffect(() => {
        const fetchStreamingVideoUrl = async () => {
            const url = await ENGINE_SERVICE.getStreamingVideoUrl();
            setStreamingVideoUrl(url);
        };

        fetchStreamingVideoUrl();
    }, []);

    const handleIsVisibleChange = (value) => {
        setIsVisible(value);
    };

    return (
        <div className="flex flex-col justify-center items-center" data-testid="streaming-video">
            <FDSwitching
                checked={isVisible}
                onChange={handleIsVisibleChange}
                name={t('countingPage.streamingCounting.video')}
                dataTestId="toggle-video"
            />
            <br />
            {isVisible && (
                <img
                    src={streamingVideoUrl}
                    alt="streaming-video"
                    className="shadow-lg object-contain xs:w-full xl:w-4/5 xxl:w-4/5"
                />
            )}
        </div>
    );
};

export default StreamingVideo;
